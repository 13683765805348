import { CampaignObjectives } from '../../common/constants/constants';

export interface RewardApiModel {
  id: string;
  accountId: string;
  name: string;
  points: number;
  isActive: boolean;
  imageUrl: string;
  value: string;
}

export enum CampaignTypes {
  CUSTOM_REWARD = 'CUSTOM_REWARD',
  MONTHLY_GIVEAWAY = 'MONTHLY_GIVEAWAY',
  NO_REWARD = 'NO_REWARD',
}

export interface CampaignApiModel {
  id: string;
  name: string;
  code: string;
  accountId: string;
  filterUrl: string;
  imageUrl: string;
  published: boolean;
  createdAt: string;
  startedAt: string;
  endedAt: string;
  numAchievements: number;
  points: number;
  downloadLink: string;
  value: string;
  activities: Activity[];
  couponEnabled: boolean;
  rewardTitle: string;
  campaignType: CampaignTypes;
  cardStyle: string;
  rewardHeader: string;
  contactPhone: string;
  campaignObjective?: CampaignObjectives;
  contact: string;
  contactMethod: string;
  welcomeVideoUrl?: string;
  showWelcomeVideo?: boolean;
  emailRequired?: boolean;
  shortcode: string;
  fulfillmentConfig: { method: SmsFulfillmentMethod };
  ctaButtonText?: string;
  ctaButtonLinkUrl?: string;
}

export interface ActivityApiModel {
  id: string;
  title: string;
  description: string;
  points: number;
  displayOrder: number;
  clientType: string | null;
  details: string | null;
  type: ActivitiesTypes;
}
export interface UserActivityApiModel {
  userId: string;
  activityTitle: string;
  activityDescription: string;
  points: number;
  count: number;
  activityType: ActivitiesTypes;
}

export interface UserIncentiveCampaignApiModel {
  campaignId: string;
  userId: string;
  active: boolean;
  fulfilled: boolean;
  fulfilledAt: string;
  campaignPoints: number;
  videoCtaClicks: number;
  videoViews: number;
  videos: number;
  campaign: CampaignApiModel;
}

export interface Activity {
  activityType: CampaignActivitiesTypes;
  points: number;
}

export enum CampaignActivitiesTypes {
  CreateVideo = 'CREATE_VIDEO',
  Conversion = 'CONVERSION',
  Shares = 'SHARE',
  CallToAction = 'CALL_TO_ACTION',
  BonusPoints = 'BONUS_POINTS',
}

export enum ActivitiesTypes {
  CreateStory = 'CREATE_VIDEO',
  CreateVideo = 'CREATE_VIDEO',
  Donation = 'DONATION',
  EnterEnoughEmails = 'ENTER_ENOUGH_EMAILS',
  CTA = 'CALL_TO_ACTION',
  Shares = 'SHARE',
  Bonus = 'BONUS_POINTS',
  FirstDonation = 'FIRST_DONATION',
  HitFundraisingGoal = 'HIT_FUNDRAISING_GOAL',
  ReferVisitor = 'REFER_VISITOR',
  SendAutomatedEmails = 'SEND_AUTOMATED_EMAILS',
  StoryView = 'VIDEO_VIEW',
  Featured = 'GET_FEATURED',
  ReferPurchaser = 'REFER_PURCHASER',
  HitVideosCreatedGoal = 'HIT_VIDEOS_CREATED_GOAL',
  HitCalendarEngagementGoal = 'HIT_CALENDAR_ENGAGEMENT_GOAL',
  Engagement = 'ENGAGEMENT',
  VideoCreated = 'VIDEO_CREATED',
  VideosViewed = 'VIDEO_VIEWED',
  VideosFeatured = 'VIDEO_FEATURED',
  Conversions = 'CONVERSION',
  VideoCreationGoals = 'VIDEO_CREATION_GOAL_REACHED',
  VideoEngagementGoals = 'VIDEO_ENGAGEMENT_GOAL_REACHED',
  VideoShared = 'VIDEO_SHARED',
}

export enum SmsFulfillmentMethod {
  Manual = 'MANUAL_SMS',
  Automatic = 'AUTOMATIC_SMS',
}
