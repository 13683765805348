import { eventProps } from '../../../utils/analytics/analytics';
import { StoryDetails } from '../../api/models/auth';

// import { ClientsTypes } from '../../api/models/common';
import { WebappLoadingCardModel, WebAppTipsModel } from '../../api/models/venue';
import { Frame } from '../../common/components/UploadedVideoPreview/SelectThumbnailModal/SelectThumbnailModal';
import { EventNames } from '../../common/constants/constants';
import { IEventTracker } from '../../common/hooks/useTrackEvent';

export function getSupportedMimeTypes(): string[] {
  const VIDEO_TYPES = ['webm', 'ogg', 'mp4', 'x-matroska'];
  const VIDEO_CODECS = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus'];

  const supportedTypes: string[] = [];
  VIDEO_TYPES.forEach((videoType) => {
    const type = `video/${videoType}`;
    VIDEO_CODECS.forEach((codec) => {
      const variations = [
        `${type};codecs=${codec}`,
        `${type};codecs:${codec}`,
        `${type};codecs=${codec.toUpperCase()}`,
        `${type};codecs:${codec.toUpperCase()}`,
        `${type}`,
      ];
      variations.forEach((variation) => {
        // @ts-ignore
        if (MediaRecorder && MediaRecorder.isTypeSupported(variation)) supportedTypes.push(variation);
      });
    });
  });
  return supportedTypes;
}

export const getVideoInMp4 = (url: string) => {
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + '.mp4';
};

export const getImageJPG = (url: string) => {
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + '.jpg';
};

export const getImageInFormat = (url: string, format: string) => {
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + `/${format}`;
};

export const getVideoPoster = (url: string) => {
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + '.jpg';
};

export const getVideoPosterWithCloudinary = (URL: string, trackEvent?: IEventTracker) => {
  try {
    let url = googleStorageVideoUrlToCloudinaryUrl(URL);
    fetch(url);
    let l = url.split('.');
    l.pop();
    let b = l.join('.');

    trackEvent?.({ action: EventNames.Thumbnail_Generated });
    return b + '.jpg';
  } catch (error) {
    trackEvent?.({ action: EventNames.Thumbnail_Generation_Failed });
    return '';
  }
};

export const uploadFile = async (file: File, uploadUrl: string) => {
  const CHUNK_SIZE = 256 * 1024; // 256KB
  let uploadedBytes = 0;

  while (uploadedBytes < file.size) {
    const chunk = file.slice(uploadedBytes, uploadedBytes + CHUNK_SIZE);
    try {
      const res = await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Range': `bytes ${uploadedBytes}-${uploadedBytes + chunk.size - 1}/${file.size}`,
        },
        body: chunk,
      });

      if (!res.ok && res.status !== 308 && res.status !== 200) {
        throw new Error('Failed to upload chunk');
      }
      if (res.ok && res.status === 200) {
        return { success: true };
      }
    } catch (err) {
      console.log('error upload lets see', err);
      return { success: true, error: err };
    }

    uploadedBytes += chunk.size;
  }
};

export const uploadChunk = async (uploadUrl: string, chunk: Blob, chunkIndex: number, fileSize: number) => {
  const chunkSize = chunk.size;
  const startByte = chunkIndex * chunkSize;
  const endByte = Math.min((chunkIndex + 1) * chunkSize - 1, fileSize - 1);

  const headers = new Headers();
  headers.append('Content-Range', `bytes ${startByte}-${endByte}/${fileSize}`);
  headers.append('Content-Type', 'application/octet-stream'); // Ensure the correct content type for binary data

  const response = await fetch(uploadUrl, {
    method: 'PUT',
    headers: headers,
    body: chunk, // Directly send the chunk (Blob) as the request body
  });

  if (!response.ok) {
    throw new Error(`Failed to upload chunk ${chunkIndex}`);
  }

  return response;
};

export const createChunks = (file: File, chunkSize: number) => {
  const chunks: Array<Blob> = [];
  let start = 0;
  while (start < file.size) {
    const chunk = file.slice(start, start + chunkSize);
    chunks.push(chunk);
    start += chunkSize;
  }
  return chunks;
};

export const uploadFileInChunks = async (file: File, chunkSize: number, maxConcurrency: number, uploadUrl: string) => {
  const chunks = createChunks(file, chunkSize);

  // Upload chunks in batches with limited concurrency
  let index = 0;
  const batchUpload = async () => {
    const batchPromises = [];

    // Process chunks in batches based on maxConcurrency
    for (let i = 0; i < maxConcurrency && index < chunks.length; i++) {
      const chunk = chunks[index];
      batchPromises.push(uploadChunk(uploadUrl, chunk, index, file.size));
      index++;
    }

    try {
      await Promise.all(batchPromises); // Upload the current batch in parallel
    } catch (error) {
      console.error('Error during chunk upload:', error);
      // Optionally, retry the failed chunks
    }

    if (index < chunks.length) {
      // Continue uploading remaining chunks
      await batchUpload();
    }
  };

  await batchUpload();
  console.log('File upload completed!');
};

export const asyncgetVideoPosterWithCloudinary = async (
  URL: string,
  trackEvent?: (action: string) => void,
  format: string = '.jpg',
) => {
  try {
    let url = googleStorageVideoUrlToCloudinaryUrl(URL);
    let l = url.split('.');
    l.pop();
    let b = l.join('.');

    trackEvent?.(EventNames.Thumbnail_Generated);
    const newurl = b + format;
    await fetch(newurl);
    return newurl;
  } catch (error) {
    trackEvent?.(EventNames.Thumbnail_Generation_Failed);
    return '';
  }
};

export const googleStorageToCloudinary = (url: string, withCloudinaryCheck?: boolean) => {
  if (withCloudinaryCheck && url.includes('cloudinary')) {
    return removeFilterFromUrl(url);
  }
  let l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  return l;
};

export const asyncGoogleStorageToCloudinary = async (url: string) => {
  let l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  await fetch(l);
  return l;
};

export const googleStorageVideoUrlToCloudinaryUrl = (url: string) => {
  let l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/v1/');
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/v1/');
  }
  return l;
};

export const googleStorageImageUrlToCloudinaryUrl = (url: string) => {
  const l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    return l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }

  if (l.includes('http://storage.googleapis.com/')) {
    return l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }

  return l;
};

export const getCloudinaryUrl = async (url: string, filterPublicId: string) => {
  try {
    let l = url.split('?')[0] || '';

    if (l.includes('https://storage.googleapis.com/')) {
      l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/');
    }
    if (l.includes('http://storage.googleapis.com/')) {
      l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/');
    }

    const [first, second] = l.split('/upload/');
    const transformed = `${first}/upload/ar_9:16,c_fill,w_540,h_960/c_fit,g_south_west,${filterPublicId},w_540,h_960/v1/${second}`;

    const res = await fetch(transformed);
    console.log(res);

    return getVideoInMp4(transformed);
  } catch (error) {
    console.log(error);
  }
};

const CLICK_LINK_BELOW_DEV_URL = 'c_fit,g_south_west,y_-100,l_sv-dev-assets:click_link,w_555';
const CLICK_LINK_BELOW_PROD_URL = 'c_fit,g_south_west,y_-100,l_sv-prod-assets:click_link,w_555';

const PLAY_ICON_DEV_URL = 'c_fit,g_center,l_sv-dev-assets:playiconv2,w_50';
const PLAY_ICON_PROD_URL = 'c_fit,g_center,l_sv-dev-assets:prod-playiconv2,w_50';

export type Bubble_Talk_Size = 'md' | 'sm';

const TALK_BUBBLE_OVERLAY_DEV_URL = (size: Bubble_Talk_Size) => {
  return `c_fit,g_north,l_sv-dev-assets:talk_bubble_overlay_${size},y_20,w_380`;
};
const TALK_BUBBLE_OVERLAY_PROD_URL = (size: Bubble_Talk_Size) => {
  return `c_fit,g_north,l_sv-prod-assets:talk_bubble_overlay_${size},y_20,w_380`;
};

export const getThumbnailWithBubbleTalk = (link: string, size: Bubble_Talk_Size) => {
  let output = '';
  const url = googleStorageToCloudinary(link);
  const [first, second] = url.split('/v1/');
  if (url.includes('sv-dev-assets')) {
    output = `${first}/${TALK_BUBBLE_OVERLAY_DEV_URL(size)}/v1/${second}`;
  }
  if (url.includes('sv-prod-assets')) {
    output = `${first}/${TALK_BUBBLE_OVERLAY_PROD_URL(size)}/v1/${second}`;
  }
  if (url.includes('sv-dev-stories')) {
    output = `${first}/${TALK_BUBBLE_OVERLAY_DEV_URL(size)}/v1/${second}`;
  }
  if (url.includes('sv-prod-stories')) {
    output = `${first}/${TALK_BUBBLE_OVERLAY_PROD_URL(size)}/v1/${second}`;
  }
  return output;
};

export const getThumbnailWithClickBelow = (url: string) => {
  let output = '';
  const [first, second] = url.split('/v1/');
  if (url.includes('sv-dev-assets')) {
    output = `${first}/${CLICK_LINK_BELOW_DEV_URL}/v1/${second}`;
  }
  if (url.includes('sv-prod-assets')) {
    output = `${first}/${CLICK_LINK_BELOW_PROD_URL}/v1/${second}`;
  }
  return output;
};

export const getThumbnailWithPlayIcon = (link: string) => {
  let output = '';
  const url = googleStorageToCloudinary(link);
  const [first, second] = url.split('/v1/');
  if (url.includes('sv-dev-assets')) {
    output = `${first}/${PLAY_ICON_DEV_URL}/v1/${second}`;
  }
  if (url.includes('sv-prod-assets')) {
    output = `${first}/${PLAY_ICON_PROD_URL}/v1/${second}`;
  }
  if (url.includes('sv-dev-stories')) {
    output = `${first}/${PLAY_ICON_DEV_URL}/v1/${second}`;
  }
  if (url.includes('sv-prod-stories')) {
    output = `${first}/${PLAY_ICON_PROD_URL}/v1/${second}`;
  }
  return output;
};

export const getThumbnailWithPlayIconV2 = (link: string) => {
  let output = '';
  let url = link;

  if (!url.includes('/v1/')) {
    url = googleStorageToCloudinary(link);
  }

  const [first, second] = url.split('/v1/');
  if (url.includes('sv-dev-assets')) {
    output = `${first}/${PLAY_ICON_DEV_URL}/v1/${second}`;
  }
  if (url.includes('sv-prod-assets')) {
    output = `${first}/${PLAY_ICON_PROD_URL}/v1/${second}`;
  }
  if (url.includes('sv-dev-stories')) {
    output = `${first}/${PLAY_ICON_DEV_URL}/v1/${second}`;
  }
  if (url.includes('sv-prod-stories')) {
    output = `${first}/${PLAY_ICON_PROD_URL}/v1/${second}`;
  }
  return output;
};

const getVideoWidth = (url: string) => {
  const start = 'w_';
  const end = '/';
  const [, part2] = url.split(start);
  if (!part2) return '';
  const [textWidth] = part2.split(end);
  return textWidth;
};

export const getNamedThumbnailForIOS = (thumbnailUrl: string, name: string, displayName: string | null) => {
  // let cropType = 'fit';
  // const userName = displayName || name;
  // if (userName.length > 12) {
  //   cropType = 'limit';
  // }
  let url = googleStorageToCloudinary(thumbnailUrl);

  const BORDER_WIDTH = 12;
  let videoWidth = 0;
  let textWidth = getVideoWidth(url);

  videoWidth = Number(textWidth) || 0;
  videoWidth = videoWidth - BORDER_WIDTH * 2;
  console.log(name, displayName);

  // const comma = '%E2%80%9A';
  // const topText = `Video Message from ${userName}`;

  // const bottomText = `If ${userName} is not in your contacts${comma} reply with a "Y" to activate their video
  // message`;

  // const topTextString =
  // `b_rgb:2E3039,bo_${BORDER_WIDTH}px_solid_rgb:2E3039,c_${cropType},co_rgb:ffffff,g_north_east,l_text:montserrat_20_bold_white:${topText},w_${videoWidth}`;
  // const bottomTextString =
  // `b_rgb:2E3039,bo_${BORDER_WIDTH}px_solid_rgb:2E3039,c_fit,co_rgb:ffffff,g_south_west,l_text:montserrat_18_white:${bottomText},w_${videoWidth}`;

  const [first, second] = url.split('/v1/');
  // const output = `${first}/${bottomTextString}/v1/${second}`;
  const output = `${first}/v1/${second}`;

  return output;
};

export const getNamedThumbnailForAndroid = (thumbnailUrl: string, name: string, displayName: string | null) => {
  // let cropType = 'fit';
  // const userName = displayName || name;
  // if (userName.length > 12) {
  //   cropType = 'limit';
  // }
  let url = googleStorageToCloudinary(thumbnailUrl);

  const BORDER_WIDTH = 12;

  let textWidth = getVideoWidth(url);
  let videoWidth = Number(textWidth) || 0;
  videoWidth = videoWidth - BORDER_WIDTH * 2;
  console.log(name, displayName);

  // const topText = `Video Message from ${userName}`;
  // const topTextString =
  // `b_rgb:2E3039,bo_${BORDER_WIDTH}px_solid_rgb:2E3039,c_${cropType},co_rgb:ffffff,g_north_east,l_text:montserrat_20_bold_white:${topText},w_${videoWidth}`;
  const [first, second] = url.split('/v1/');
  // const output = `${first}/${topTextString}/v1/${second}`;
  const output = `${first}/v1/${second}`;

  return output;
};

export function imageToBlob(imageURL: string): Promise<Blob> {
  const img = new Image();
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  img.crossOrigin = '';
  img.src = imageURL;
  return new Promise((resolve) => {
    img.onload = function () {
      c.width = img.naturalWidth;
      c.height = img.naturalHeight;
      ctx && ctx.drawImage(img, 0, 0);
      c.toBlob(
        (blob) => {
          // here the image is a blob
          resolve(blob as Blob);
        },
        'image/jpg',
        0.75,
      );
    };
  });
}

export async function copyImage(url: string, isChromeAndNotiOS: boolean) {
  if (window) {
    const imageURL = googleStorageToCloudinary(url);
    if (isChromeAndNotiOS) {
      const bloburl = imageURL.replace('.jpg', '.png');
      const blob = await imageToBlob(bloburl);
      await navigator.clipboard.write([
        // @ts-ignore
        new ClipboardItem({ 'image/png': blob }),
      ]);
    } else {
      const blob = imageToBlob(imageURL);
      const item = new ClipboardItem({ 'image/png': blob });
      navigator.clipboard.write([item]);
    }
  }
}

const getHtml = (url: string, imageUrl: string, introText?: string) => {
  const img = getThumbnailWithPlayIcon(imageUrl);
  return `
  <div>
    ${
      introText
        ? `<p style= '
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    display: flex;'>${introText}</p>`
        : `<p></p>`
    }
    <a href='${url}' style='box-sizing:border-box; font-size:10px;'>
    <img
          title='video'
          src='${img}'
          alt='Thumbnail for video'
          style='box-sizing: border-box; padding: 0px; margin: 0px; width: auto; height: 276px;' />
    </a>
    <a  href='${url}'
        style='box-sizing: border-box;  padding: 0px; 
        font-size: 14px; font-weight: 400; text-decoration: none'>
    <p  style='height:30px; padding: 10px 42px; 
        width:140px; background: #17B790; border-radius: 8px; text-decoration: none; color:white; text-align:center; padding-top:10px'>
    Open Video
    </p>
    </a>
    </div>
    `;
};

export async function copyHtml(imageUrl: string, url: string, isChrome: boolean, introText?: string) {
  if (window) {
    const text = getHtml(url, imageUrl, introText);
    const type = 'text/html';
    const hasClipboardItem = typeof ClipboardItem !== 'undefined';
    if (!hasClipboardItem) {
      console.log('hasClipboardItem', hasClipboardItem);
    }
    if (hasClipboardItem) {
      if (isChrome) {
        const blob = new Blob([text], { type });
        // @ts-ignore
        const data = [new ClipboardItem({ [type]: blob })];
        try {
          await navigator.clipboard.write(data);
        } catch (err) {
          console.log('clipboard error', err);
        }
      } else {
        const getAsyncBlob = async (text: string) => {
          const blob = new Blob([text], { type });
          return blob;
        };
        const blob = await getAsyncBlob(text);
        const textType = 'text/plain';
        const blob2 = new Blob(['   '], { type: textType });
        // @ts-ignore
        const data = [new ClipboardItem({ [type]: blob }), new ClipboardItem({ [textType]: blob2 })];
        navigator.clipboard.write(data);
      }
    } else {
      handleCopyFallback(imageUrl, url);
    }
  }
}

export const handleCopyFallback = async (url: string, videoUrl?: string) => {
  const thumbnailWithPlayIcon = getThumbnailWithPlayIconV2(url);
  // Detect all style sheets of the page
  var activeSheets = Array.prototype.slice.call(document.styleSheets).filter(function (sheet) {
    return !sheet.disabled;
  });

  if (videoUrl) {
    console.log('fallback used with vide Url');
    const img = document.createElement('img');
    img.src = thumbnailWithPlayIcon;
    img.style.minHeight = '300px';
    img.style.minWidth = '140px';
    img.style.objectFit = 'contain';
    const a1 = document.createElement('a');
    a1.href = videoUrl;
    const a2 = document.createElement('a');
    a2.href = videoUrl;
    a2.innerText = 'Watch Now';
    a1.appendChild(img);
    const div2 = document.createElement('br');
    const div = document.createElement('div');
    div.style.minHeight = '400px';
    div.appendChild(a1);
    div.appendChild(div2);
    div.appendChild(a2);
    document.body.appendChild(div);

    window?.getSelection()?.removeAllRanges();

    const myrange = document.createRange();
    myrange.setStartBefore(div);
    myrange.setEndAfter(div);
    myrange.selectNode(div);

    var sel = window.getSelection();
    sel?.addRange(myrange);
    document.execCommand('copy');
    for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true;
    const status = document.execCommand('copy');
    for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false;
    document.body.removeChild(div);
    if (status) {
      return true;
    } else {
      throw new Error('no available');
    }
  } else {
    console.log('fallback used');
    const img = document.createElement('img');
    img.src = url;
    document.body.appendChild(img);

    const myrange = document.createRange();
    myrange.setStartBefore(img);
    myrange.setEndAfter(img);
    myrange.selectNode(img);

    var sel = window.getSelection();
    sel?.addRange(myrange);
    const status = document.execCommand('copy');
    document.body.removeChild(img);
    if (status) {
      return true;
    } else {
      throw new Error('no available');
    }
  }
};

export const getImageUrlFromProperties = (
  property: WebappLoadingCardModel | WebappLoadingCardModel[] | WebAppTipsModel | WebAppTipsModel[],
) => {
  if (Array.isArray(property)) {
    return property[0]['tip-media-url'] || '';
  } else {
    return property['tip-media-url'] || '';
  }
};
export const getPropertyArrayFromProperties = (
  property: WebappLoadingCardModel | WebappLoadingCardModel[] | WebAppTipsModel | WebAppTipsModel[],
) => {
  if (Array.isArray(property)) {
    return property;
  } else {
    return [property];
  }
};

interface VideoResolution {
  width: number;
  height: number;
}

export const getUrlOriginalResolutionWithFilter = async (url: string) => {
  let result = url.replace(/,w_\d+,h_\d+/, '');

  const video = document.createElement('video');
  video.src = result;

  const videoResolution = await new Promise<VideoResolution>((resolve) => {
    video.addEventListener(
      'loadedmetadata',
      () => {
        resolve({
          width: video.videoWidth,
          height: video.videoHeight,
        });
      },
      false,
    );
  });

  result = result.replace(/w_\d+/, `w_${videoResolution.width}`).replace(/h_\d+/, `h_${videoResolution.height}`);

  if (!result.includes('fl_attachment')) {
    result = result.replace('/upload/', '/upload/fl_attachment/');
  }
  return result;
};

export const prefetchUrl = async (url: string) => {
  return await fetch(url)
    .then((response) => response?.body?.getReader())
    .then((reader) => {
      const chunk = reader?.read();
      reader?.cancel();
      return chunk;
    });
};

const prefetchCloudinaryVideos = async (url: string, transformedUrl: string) => {
  // const [first, second] = url.split('/upload/');

  const [first, second] = url.split('/upload/');
  const urlWithoutLogo =
    url.includes(',l_sv-prod-assets') || url.includes(',l_sv-dev-assets')
      ? url
      : `${first}/upload/fl_attachment/ar_9:16,c_fill/c_scale,fl_relative/v1/${second}`;

  await fetch(urlWithoutLogo)
    .then((response) => response?.body?.getReader())
    .then((reader) => {
      const chunk = reader?.read();
      reader?.cancel();
      return chunk;
    });

  await fetch(transformedUrl)
    .then((response) => response?.body?.getReader())
    .then((reader) => {
      const chunk = reader?.read();
      reader?.cancel();
      return chunk;
    });
  // fetch(`${first}/upload/fl_attachment/ar_9:16,c_fill/c_scale,fl_relative/v1/${second}`);
  getUrlOriginalResolutionWithFilter(transformedUrl).then((resultUrl) => {
    fetch(resultUrl);
  });
};

export const getVideoURLWithAspectRatio = (url: string, width: number, fill = true) => {
  try {
    let l = url.split('?')[0] || '';

    if (l.includes('https://storage.googleapis.com/')) {
      l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/');
    }
    if (l.includes('http://storage.googleapis.com/')) {
      l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/');
    }

    // const W = width < 375 ? 400 : width; // temporary value

    const [first, second] = l.split('/upload/');
    // add a check see if second is defined
    const transformed = `${first}/upload/ar_9:16,${fill ? 'c_fill,' : ''}w_${540},h_960/v1/${second}`;

    prefetchCloudinaryVideos(l, transformed);
    return transformed;
  } catch (error) {
    console.log(error);
    console.log(width);
    return '';
  }
};

export const CloudinaryURL = (url: string, filterPublicId: string, width: number, fill = true) => {
  try {
    let l = url.split('?')[0] || '';

    if (l.includes('https://storage.googleapis.com/')) {
      l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/');
    }
    if (l.includes('http://storage.googleapis.com/')) {
      l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/');
    }

    // const W = width < 375 ? 400 : width; // temporary value

    const [first, second] = l.split('/upload/');
    // add a check to see if second is defined
    const transformed = `${first}/upload/ar_9:16,${
      fill ? 'c_fill,' : ''
    }w_${540},h_960/c_scale,fl_relative,${filterPublicId},w_${540},h_${960},y_0/v1/${second}`;

    prefetchCloudinaryVideos(l, transformed);

    return getVideoInMp4(transformed);
  } catch (error) {
    console.log(error);
    console.log(width);
    return '';
  }
};

export const getCloudinaryFrames = (url: string, intervals: number[]): Promise<Frame[]> => {
  const [first, second] = url.split('/upload/');
  // add a check see if second is defined
  return Promise.all(
    intervals.map(async (item) => {
      const transformed = `${first}/upload/so_${item}/${second}`;
      await fetch(transformed);
      return {
        thumbnail: getImageJPG(transformed),
        time: item,
      };
    }),
  );
};

// https://res.cloudinary.com/socialvenu/video/upload/ar_4:5,c_fill,w_350/c_fit,g_south_west,l_sv-dev-assets:concord_filter,w_350/v1/sv-dev-stories/stories/72104cbd-0b3f-41d1-9a55-9fb41fa1ae6e.mp4

export const createTextOverlayUrl = (text: string, x: number, y: number, url: string) => {
  if (!text) {
    return url;
  }
  let cropType = 'lfill';
  if (text.length > 30) {
    cropType = 'fit';
  }
  // const textWidth = 230;
  let X = x < 0 ? 0 : x;
  let Y = y < 0 ? 0 : y;
  const gravity = X === 0 && Y === 0 ? 'g_center' : 'g_north';

  const replacedStr = replaceSymbols(text);
  const textString = `co_rgb:fff,c_${cropType},l_text:open%20sans_16_solid_line_height_24_font_size_16:${replacedStr},r_5,w_380,h_200,x_${X},y_${Y},${gravity}`;
  const [first, second] = url.split('/v1/');
  if (first && second) {
    return `${first}/${textString}/v1/${second}`;
  }
  return url;
};

export const removeTextOverlay = (url: string) => {
  if (url) {
    const cropType = url.includes('lfill') ? 'lfill' : 'fit';
    const gravity = url.includes('g_center') ? 'g_center' : 'g_north';
    const regexp = new RegExp(`,c_${cropType}(.*?)${gravity}`);
    const arrUrl = url.split(regexp);
    return arrUrl[2] ? arrUrl[0].concat(arrUrl[2]) : url;
  }
  return url;
};

export const getUserStoryDetails = (defaults: StoryDetails, name: string, org: string): StoryDetails => {
  return {
    ...defaults,
    metaTitle: defaults.metaTitle.replace('{Display Name}', name).replace('{Org Name}', org),
    videoText: '' /* defaults.videoText.replace('{Display Name}', name).replace('{Org Name}', org) */,
  };
};

export const getDefaultStoryDetails = (
  userDetails: StoryDetails | null,
  accountDetails: StoryDetails | undefined,
  mockDetails: StoryDetails,
  returnUrl?: string,
) => {
  return {
    metaTitle: accountDetails?.webAppSettings?.metaTitle
      ? userDetails?.metaTitle || accountDetails?.metaTitle || mockDetails.metaTitle
      : accountDetails?.metaTitle || mockDetails.metaTitle,

    metaDescription: userDetails?.metaDescription || accountDetails?.metaDescription || mockDetails.metaDescription,
    videoText:
      /* accountDetails?.webAppSettings?.videoText
? userDetails?.videoText || accountDetails?.videoText || mockDetails.videoText
: accountDetails?.videoText ||  */ '',

    ctaBtnText: accountDetails?.webAppSettings?.ctaBtnText
      ? userDetails?.ctaBtnText || accountDetails?.ctaBtnText || mockDetails.ctaBtnText
      : accountDetails?.ctaBtnText || mockDetails.ctaBtnText,

    ctaBtnLinkUrl: accountDetails?.webAppSettings?.ctaBtnLinkUrl
      ? userDetails?.ctaBtnLinkUrl || returnUrl || accountDetails?.ctaBtnLinkUrl || mockDetails.ctaBtnLinkUrl
      : accountDetails?.ctaBtnLinkUrl || returnUrl || mockDetails.ctaBtnLinkUrl,

    editable: accountDetails?.editable ?? mockDetails.editable,

    videoMessage: accountDetails?.webAppSettings?.videoMessage
      ? userDetails?.videoMessage || accountDetails?.videoMessage || mockDetails.videoMessage
      : accountDetails?.videoMessage || mockDetails.videoMessage,
  };
};

export const getVideoWithoutText = (url: string) => {
  if (!url) return '';
  const [first, second] = url.split('co_rgb:fff');
  if (!second) return url;
  let [, ...rest] = second.split('/');
  return `${first}${rest.join('/')}`;
};

// const webPackSupportedReplace = function(instance: string, match: string, replace: string) {
//   return instance.replace(new RegExp(match, 'g'), () => replace);
// };

// utils.ts?8212:511 Uncaught (in promise) SyntaxError: Invalid regular expression: /?/: Nothing to repeat

export function replaceSymbols(text: string) {
  const symbols = [
    { key: `%`, code: '%2525' },
    { key: `?`, code: '%253F' },
    { key: `:`, code: '%253A' },
    { key: `,`, code: '%252C' },
    { key: `;`, code: '%253B' },
    { key: `"`, code: '%2522' },
    { key: `$`, code: '%2524' },
    { key: `&`, code: '%2526' },
    { key: `#`, code: '%2523' },
    { key: `@`, code: '%2540' },
    { key: `+`, code: '%252B' },
    { key: `=`, code: '%253D' },
    { key: `^`, code: '%255E' },
    { key: `[`, code: '%255B' },
    { key: `]`, code: '%255D' },
    { key: `{`, code: '%257B' },
    { key: `}`, code: '%257D' },
  ];
  let output = text;
  for (let i = 0; i < symbols.length; i++) {
    if (output.includes(symbols[i].key)) {
      output = output.replace(symbols[i].key, symbols[i].code);
    }
  }
  return output;
}

export const removeFilterFromUrl = (url: string) => {
  const filterStartIndex = url.indexOf('l_sv-dev-assets');
  const filterStartIndexprod = url.indexOf('l_sv-prod-assets');
  if (filterStartIndex === -1 && filterStartIndexprod === -1) return url;
  return url.split('/').reduce((acc, curr) => {
    return curr.includes('l_sv-dev-assets') || curr.includes('l_sv-prod-assets') ? acc : acc ? `${acc}/${curr}` : curr;
  }, '');
};

// utils/uploadSpeedTest.ts
type UploadSpeedResult = {
  speedMbps: number;
  uploadTimeSeconds: number;
};

async function singleUploadSpeedTest(sizeInBytes: number): Promise<UploadSpeedResult> {
  const SIGNED_URL_ENDPOINT =
    'https://us-east1-quantum-potion-305918.cloudfunctions.net/sv-upload-speed-test-signed-url';

  const signedUrlRes = await fetch(SIGNED_URL_ENDPOINT, { method: 'POST' });
  if (!signedUrlRes.ok) {
    throw new Error('Failed to get signed URL');
  }

  const { url, headers } = await signedUrlRes.json();

  const rawData = new Uint8Array(sizeInBytes);
  rawData.fill(97);

  const start = performance.now();
  const uploadRes = await fetch(url, {
    method: 'PUT',
    headers: { ...headers },
    body: rawData,
  });
  const end = performance.now();

  if (!uploadRes.ok) {
    throw new Error(`Upload failed with status ${uploadRes.status}`);
  }

  const uploadTimeSeconds = (end - start) / 1000;
  const speedMbps = (sizeInBytes * 8) / (uploadTimeSeconds * 1_000_000);

  return { speedMbps, uploadTimeSeconds };
}

/**
 * Runs multiple upload speed tests and returns the average result.
 */
export const measureAverageUploadSpeed = async (
  sizeInBytes: number,
  rounds: number = 3,
  delayMs: number = 500,
  trackEvent: (arg: eventProps) => void,
): Promise<UploadSpeedResult & { rounds: number }> => {
  const results: UploadSpeedResult[] = [];

  for (let i = 0; i < rounds; i++) {
    trackEvent({ action: EventNames.upload_speed_test_initiated, location: window.location.href });
    const result = await singleUploadSpeedTest(sizeInBytes);
    trackEvent({ action: EventNames.upload_speed_test_completed, location: window.location.href, ...result });
    results.push(result);

    // Compute average so far
    const avgSpeed = results.reduce((acc, r) => acc + r.speedMbps, 0) / results.length;
    const avgTime = results.reduce((acc, r) => acc + r.uploadTimeSeconds, 0) / results.length;

    const partial = {
      speedMbps: avgSpeed,
      uploadTimeSeconds: avgTime,
      rounds: results.length,
    };

    // ✅ Store partial average after each round
    sessionStorage.setItem('uploadSpeedResult', JSON.stringify(partial));

    if (i < rounds - 1) {
      await new Promise((res) => setTimeout(res, delayMs));
    }
  }

  // Return final average
  const finalAvgSpeed = results.reduce((acc, r) => acc + r.speedMbps, 0) / results.length;
  const finalAvgTime = results.reduce((acc, r) => acc + r.uploadTimeSeconds, 0) / results.length;

  return {
    speedMbps: finalAvgSpeed,
    uploadTimeSeconds: finalAvgTime,
    rounds: results.length,
  };
};

export const getCachedUploadSpeed = () => {
  const cached = sessionStorage.getItem('uploadSpeedResult');
  if (!cached) return null;
  try {
    return JSON.parse(cached) as {
      speedMbps: number;
      uploadTimeSeconds: number;
      rounds: number;
    };
  } catch {
    return null;
  }
};

export const getChunkSizeFromUploadSpeed = (speedMbps: number): number => {
  let chunkSize: number;
  // if (speedMbps < 1) chunkSize = 256 * 1024 * 6; // 256 KB x 6 = 1.5 MB
  // if (speedMbps < 5) chunkSize = 256 * 1024 * 6 * 5 * 2; // 256 KB x 6 x 5 = 7.8 MB
  // else if (speedMbps < 10) chunkSize = 256 * 1024 * 6 * 7 * 2; // 256 KB x 6 x 5 = 11.01 MB
  // else if (speedMbps < 15) chunkSize = 256 * 1024 * 6 * 10 * 2; // 15.728640 MB
  // else if (speedMbps < 20) chunkSize = 256 * 1024 * 6 * 13 * 2; // 20.447232 MB
  // else if (speedMbps < 25) chunkSize = 256 * 1024 * 6 * 17 * 2; // 26.738688 MB
  // else if (speedMbps < 30) chunkSize = 256 * 1024 * 6 * 20 * 2; // 31.457280 MB
  // else chunkSize = 256 * 1024 * 6 * 32 * 2; // 50.331648 MB

  if (speedMbps < 5) chunkSize = 256 * 1024 * 6 * 5 * 2; // 15MB
  else if (speedMbps < 10) chunkSize = 256 * 1024 * 6 * 7 * 2; // 22 MB
  // else if (speedMbps < 15) this.chunkSize = 256 * 1024 * 6 * 10 * 2; // 15.728640 MB
  else if (speedMbps < 20) chunkSize = 256 * 1024 * 5 * 12 * 3; // 47MB
  // else if (speedMbps < 25) this.chunkSize = 256 * 1024 * 6 * 17 * 2; // 26.738688 MB
  // else if (speedMbps < 30) this.chunkSize = 256 * 1024 * 6 * 20 * 2; // 31.457280 MB
  else chunkSize = 256 * 1024 * 6 * 32 * 2; // 100.331648 MB

  return chunkSize;
};

export const MINCHUNKSIZE = 256 * 1024; // 256KB

export const isUploadCompleted = (id: string): boolean => {
  try {
    const uploadedData = JSON.parse(localStorage.getItem('uploadedData') || '{}');
    return uploadedData.hasOwnProperty(id);
  } catch (err) {
    console.error('Failed to parse uploadedData from localStorage:', err);
    return false;
  }
};
